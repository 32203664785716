import { getLocalCart } from 'store/actions';
import useCartActions from './useCartAction';
import { useRemoveSpace } from './useRemoveSpace';

import { TOKEN_PREFIX } from 'helpers/api_methods';
import React from 'react';

export const useCallToAction = (
  product,
  dispatch,
  type,
  stock,
  history,
  setIsOpen,
) => {
  const token = localStorage.getItem(TOKEN_PREFIX);

  // console.log(setIsOpen);
  switch (type) {
    case 'product':
      if (product?.primaryLang?.name?.length > 0) {
        history.push(`/products/${useRemoveSpace(product?.primaryLang?.name)}`);
      }
      break;
    case 'checkOut':
    case 'cart':
      // const options = {

      //   product: product?._id,
      //   varient: product?.varients[0]?._id,
      //   quantity: 1,
      //   operation: 'add',
      // };
      // if (stock >= 1) {
      //   useCartActions(options, dispatch, { ...product, stock: stock });
      //   if (!token) {
      //     dispatch(getLocalCart());
      //   }
      // }

      // if (type === 'checkOut') {
      //   history.push(`/cart`);
      // }
      if (product?.hasCustomizationOption === true) {
        setIsOpen({ status: true });
      } else {
        const options = {
          product: product?._id,
          varient: product?.hasVarients
            ? varients?._id
              ? varients?._id
              : product?.varients[0]?._id
            : '',
          quantity: 1,
          operation: 'add',
        };

        if (stock >= 1) {
          useCartActions(options, dispatch, { ...product, stock: stock }, true);
          if (!token) {
            dispatch(getLocalCart());
          }
        }
      }

      break;
  }
};
