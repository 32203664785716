import React from 'react';
import PropTypes from 'prop-types';

import empty from 'assets/imgs/shop/cart-empty.gif';
import emptyBox from 'assets/imgs/shop/Artboard 2 1.png';

import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { useWindowSize } from 'react-use';

function EmptyCartSvg({ style }) {
  const { width } = useWindowSize();
  return (
    <>
      {/* mobileView */}
      {width <= 428 && (
        <>
          <div
            className="d-flex align-items-center flex-revers-col emptyCart"
            style={style}
          >
            <div>
              {style?.isDes ? (
                <>
                  <div>
                    <h4 className=" cartempty">Your cart is empty!</h4>
                    <p className="text-black cartemptySub">
                      {/* Explore our wide selection and find something you like */}
                      Explore our wide selection and find something <br />
                      exciting you like.
                    </p>
                  </div>
                </>
              ) : (
                <p className="">Your cart is empty!</p>
              )}
              <div className="mt-30">
                <Link
                  to="/products"
                  className="m-auto  mb-5 mt-15 back-shopping-btn "
                >
                  <span className=" shopping-text hover-up mt-30">
                    {/* <i className="fi-rs-home mr-5"></i>  */}
                    Back To Shopping
                  </span>
                </Link>
              </div>
            </div>
            {/* <LazyLoad height={200}>
          <img
            src={empty}
            alt="empty-pic"
            className="h-100"
            height="400"
            width="400"
            title="cart-empty"
            loading="eager"
          />
        </LazyLoad> */}
          </div>
          <div className="p-0">
            <LazyLoad>
              <img
                src={emptyBox}
                alt="empty-pic"
                className="h-100"
                height="400"
                width="100%"
                title="cart-empty"
                loading="eager"
                style={{ objectFit: 'cover' }}
              />
            </LazyLoad>
          </div>
        </>
      )}
      {/* web */}
      {width >= 428 && (
        <>
          <div
            className="d-flex align-items-center flex-revers-col emptyCart web-empty justify-content-between "
            style={style}
          >
            <div>
              {style?.isDes ? (
                <>
                  <div>
                    <h4 className=" cartempty" style={{ color: 'black' }}>
                      Your cart is empty!
                    </h4>
                    <p className="text-black cartemptySub">
                      {/* Explore our wide selection and find something you like */}
                      Explore our wide selection and find something <br />
                      exciting you like.
                    </p>
                    <div className="mt-30">
                      <Link
                        to="/products"
                        className="m-auto  mb-5 mt-15 back-shopping-btn "
                      >
                        <span className=" shopping-text hover-up mt-30">
                          {/* <i className="fi-rs-home mr-5"></i>  */}
                          Back To Shopping
                        </span>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <p className="" style={{ color: 'black' }}>
                  Your cart is empty!
                </p>
              )}
            </div>
            <LazyLoad>
              <img
                src={emptyBox}
                alt="empty-pic"
                className="h-100"
                height="200"
                width="100%"
                title="cart-empty"
                loading="eager"
                style={{ objectFit: 'cover', width: '300px' }}
              />
            </LazyLoad>
            {/* <div className="mt-30">
              <Link
                to="/products"
                className="m-auto  mb-5 mt-15 back-shopping-btn "
              >
                <span className=" shopping-text hover-up mt-30">
                  Back To Shopping
                </span>
              </Link>
            </div> */}
          </div>
          {/* <div className="p-0">
            <LazyLoad>
              <img
                src={emptyBox}
                alt="empty-pic"
                className="h-100"
                height="400"
                width="100%"
                title="cart-empty"
                loading="eager"
                style={{ objectFit: 'cover' }}
              />
            </LazyLoad>
          </div> */}
        </>
      )}
    </>
  );
}

export default EmptyCartSvg;

EmptyCartSvg.propTypes = {
  style: PropTypes.any,
};
