import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useLangSwitch from 'components/hooks/useLanguageSwicth';
import usePrice from 'components/hooks/usePrice';
import moment from 'moment';
import getDifferenceInDays from 'components/hooks/useGetDifferenceInDays';
import { defualtProd } from 'assets/imgs/logos';
import LazyLoad from 'react-lazyload';
import startImage from '../../../assets/imgs/category/Group 46@2x.png';
import useWindow from 'components/hooks/useWindow';
import { FaStar } from 'react-icons/fa';
import useCloudinary from 'components/hooks/useCloudinaryImage';

const ProductCardOne = ({ product, className }) => {
  // const dispatch = useDispatch();

  // const user_favourite =
  //   storage.get('user_favourite') !== null && storage.get('user_favourite');

  // const handleWishlist = (productId) => {
  //   useWishlistAction(dispatch, productId);
  // };

  const { isMobile } = useWindow();

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang);

  const { currencyCode, oldPrice, price, stock, currentVarient } =
    usePrice(product);

  const createdAt = moment(product?.createdAt).format('MM/DD/YYYY');
  const today = moment(new Date()).format('MM/DD/YYYY');

  const diffInDays = getDifferenceInDays(createdAt, today);

  const removeSpace = language?.name?.replace(/ /g, '-');

  const diff = Math.abs(oldPrice - price);

  const discountPercentage =
    (oldPrice ?? 0) > price ? Math.floor((diff / oldPrice) * 100) : '';

  const discount = (oldPrice ?? 0) > price ? diff : '';

  // const { width } = useWindowSize();

  // if (!product?.isActive) return <></>;
  return (
    <div
      className={`product-cart-wrap shoplist-child ${className} position-relative mb-4`}
      style={{ border: 'none' }}
    >
      <div
        className="product-img-action-wrap shoplist flex-1 "
        style={{ border: '1px solid #e9e9e9', borderRadius: 5 }}
      >
        {discountPercentage >= 1 && (
          <div className="position-absolute star-discount-img">
            <img src={startImage} alt="discount%" />
            <div className="discount-text-product">
              <h6 className="offer-price-single-page-text1">
                {product?.discountPercentage || discountPercentage}%
              </h6>
              <h6 className="offer-price-product-card">OFF</h6>
            </div>
          </div>
        )}

        <div className="product-img product-img-zoom">
          <Link to={`/products/${removeSpace}`}>
            {/* <img
              className="default-img"
              src={`${
                useCloudinary(product.images[0]?.url, 'w_500') ?? defualtProd
              }`}
              alt="defualt-img"
              title="defualt-img"
              loading="eager"
            /> */}
            {/* <img
              className="default-img product-cart-one-mb"
              src={product.images[0]?.url || 'w_500' || defualtProd}
              alt="default-img"
              title="default-img"
              loading="eager"
            /> */}
            <LazyLoad height={200}>
              <img
                src={
                  useCloudinary(product.images[0]?.url, 'w_600') || defualtProd
                }
                className="default-img product-cart-one-mb fit-cover"
                alt="default-img"
                title="default-img"
                loading="eager"
                style={{ borderRadius: 5 }}
              />
            </LazyLoad>
            {/* {width >= 480 ? (
              <LazyLoad height={200}>
              
                <img
                  src={useCloudinary(
                    product.images[1]?.url
                      ? product.images[1]?.url
                      : product.images[0]?.url,
                    'w_100',
                  )}
                  alt="product"
                  title="product"
                  loading="eager"
                />
              
              </LazyLoad>
            ) : (
              ''
            )} */}
          </Link>
        </div>

        {/* <div className="product-action-1">
         
          <a
            aria-label="Add To Wishlist"
            className="action-btn hover-up"
            onClick={(e) => handleWishlist(product?._id)}
          >
            {user_favourite?.length >= 1 &&
            user_favourite?.includes(product?._id) ? (
              <FaHeart className="text-danger" style={{ marginTop: '-6px' }} />
            ) : (
              <FaRegHeart
                className="text-black"
                style={{ marginTop: '-6px' }}
              />
            )}
          </a>
        </div> */}

        <div
          className="product-badges product-badges-position 
          product-badges-mrg"
        >
          {stock <= 0 ? (
            <span className="text-white best curvy" style={{ zIndex: 5 }}>
              ! Out of stock
            </span>
          ) : (
            <>
              {product?.isFeatured && <span className="hot curvy">Hot</span>}
              {diffInDays >= 1 && diffInDays <= 2 && (
                <span className="new curvy">New</span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="product-content-wrap pt-1 w-100">
        <h2 className="mb-0 mt-1">
          <Link
            to={`/products/${removeSpace}`}
            className="font-normal"
            style={{ fontWeight: 'normal', width: '100%' }}
          >
            <span>
              {language?.name?.slice(0, isMobile ? 35 : 100)}
              {isMobile && language?.name?.length > 35 && '...'}&nbsp;{' '}
              {currentVarient && (
                <span style={{ whiteSpace: 'nowrap' }}>({currentVarient})</span>
              )}{' '}
            </span>
          </Link>
        </h2>
        {/* {product?.averageRating && product?.averageRating !== 0 ? (
          <div className="thumb text-center d-flex align-items-center mt-2">
            <div className="d-flex">
              <h6 className="rating-count-home">
                {product?.averageRating?.toFixed(1)}
              </h6>
              <FaStar className="start-rating" style={{ marginLeft: '5px' }} />
            </div>
          </div>
        ) : (
          <></>
        )} */}
        <div className="product-card-bottom mt-0 price-mob">
          <div className="product-price">
            {oldPrice && (
              <>
                <span
                  className="old-price font-normal m-0"
                  style={{ fontWeight: '500' }}
                >
                  {oldPrice}
                </span>
                &nbsp;
              </>
            )}
            <span
              className="font-head text-black"
              style={{
                margin: '0 5px 0 5px',
                fontWeight: '700',
                color: '#0000',
              }}
            >
              {currencyCode}
              {price}&nbsp;
            </span>
            {(product?.discountPercentage > 0 || discountPercentage > 0) && (
              <>
                {discountPercentage > 5 ? (
                  <span className="price-per">
                    {product?.discountPercentage ?? discountPercentage}% off
                  </span>
                ) : (
                  <span className="price-per">₹ {discount} off</span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(ProductCardOne);

ProductCardOne.propTypes = {
  product: PropTypes.any,
  className: PropTypes.string,
};
