import { useLocalCartPrices } from 'components/hooks/useCartAction';
import usePrice from 'components/hooks/usePrice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import {
  getLocalCart,
  hideCartNotificationAction,
  showCartNotificationAction,
} from 'store/actions';
import CartItemList from './cartItemComp2';
import { TOKEN_PREFIX } from 'helpers/api_methods';
import LazyLoad from 'react-lazyload';
import { RxCross1 } from 'react-icons/rx';
import {
  googlepay,
  mastercard,
  paytm,
  phonepay,
  rupay,
  UPI,
  visa,
} from 'assets/imgs/payment';
import useWindow from 'components/hooks/useWindow';

function CartBottomNotification() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = useWindow();

  const { cartItems, localCart, userCart, cartNoti } = useSelector((state) => ({
    cartItems: state.Cart.cartAdded,
    localCart: state.Cart.localCart,
    userCart: state.Cart.userCart,
    cartNoti: state.Layout.cartNoti,
  }));
  const token = localStorage.getItem(TOKEN_PREFIX);

  const { currencyCode } = usePrice();

  // console.log('cartNoti', cartNoti);

  // useEffect(() => {
  //   if (cartNoti === true) {
  //     setTimeout(() => {
  //       dispatch(hideCartNotificationAction());
  //     }, 4000);
  //   }
  // }, [cartNoti]);

  const { width } = useWindowSize();
  const handleCartItem = () => {
    if (userCart?.cartItems?.length >= 1) {
      return userCart?.cartItems;
    } else {
      return localCart?.filter((i) => i.quantity !== 0);
    }
  };

  // useEffect(() => {
  //   if (handleCartItem()?.length === 0) {
  //     setTimeout(() => {
  //       dispatch(hideCartNotificationAction());
  //     }, 6000);
  //   } else {
  //     dispatch(showCartNotificationAction());
  //   }
  // }, [userCart?.cartItems, localCart]);

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart());
    }
  }, []);

  const { itemTotal } = useLocalCartPrices();

  return (
    <div>
      {location?.pathname === '/cart' || location?.pathname === '/checkout' ? (
        ''
      ) : (
        <div
          className={
            'w-100 p-1 position-fixed zIndex-full bottom-0 cart-bottom-noti ' +
            `${cartNoti ? 'show' : ''}`
          }
          style={{ zIndex: '99', backgroundColor: '#eee' }}
        >
          {cartNoti && (
            <RxCross1
              color="black"
              fontSize={20}
              onClick={() => dispatch(hideCartNotificationAction())}
            />
          )}
          <div
            className={'d-flex'}
            // style={{ zIndex: '99', backgroundColor: '#eee' }}
          >
            {width > 768 && (
              <>
                {handleCartItem()
                  ?.slice(0, 1)
                  ?.map((item, i) => (
                    <ul key={i} className="mx-2">
                      <CartItemList item={item} key={i} noti={true} />
                    </ul>
                  ))}
              </>
            )}
            <div
              className={
                'w-fit d-flex align-items-center ' +
                `${width > 768 ? 'ml-auto' : 'm-auto'}`
              }
            >
              <div className="mx-4 text-center">
                <p className="mb-0 pb-0 text-black">
                  {handleCartItem()?.length}&nbsp; items in your cart
                  {width > 768 && <span>, Checkout Now</span>}
                </p>
                <p className="mb-0 pb-0 text-black">
                  Subtotal :{' '}
                  <Link to={'#!'} className="font-bold text-black fs-4">
                    {' '}
                    {currencyCode}{' '}
                    {handleCartItem()?.length > 0
                      ? userCart?.totalPrice
                        ? userCart?.totalPrice
                        : itemTotal
                      : 0}
                  </Link>
                </p>
              </div>
              {/* {width > 768 && (
            <Link
              to={"#!"}
              onClick={handleCheckout}
              className={`btn ${outOfStock && "bg-disable border-none"}`}
            >
              Checkout
            </Link>
          )} */}
              <Link
                to="/cart"
                className="btn mx-2"
                style={{ opacity: '.8', color: 'black' }}
              >
                View cart
              </Link>
            </div>
          </div>
          <div
            className="d-flex mb-5 justify-content-center align-items-center"
            style={{ gap: isMobile ? 20 : 30, marginTop: isMobile && 15 }}
          >
            <LazyLoad height={12}>
              <img
                alt="payment"
                src={paytm}
                height={'12px'}
                title="payment"
                loading="eager"
              />
            </LazyLoad>
            <LazyLoad height={12}>
              <img
                alt="payment"
                src={googlepay}
                height={'12px'}
                title="payment"
                loading="eager"
              />
            </LazyLoad>
            <LazyLoad height={12}>
              <img
                alt="payment"
                src={UPI}
                height={'12px'}
                title="payment"
                loading="eager"
              />
            </LazyLoad>
            <LazyLoad height={12}>
              <img
                alt="payment"
                src={visa}
                title="payment"
                loading="eager"
                height={'12px'}
              />
            </LazyLoad>
            <LazyLoad height={12}>
              <img
                alt="payment"
                src={rupay}
                height={'17px'}
                title="payment"
                loading="eager"
              />
            </LazyLoad>
            <LazyLoad height={12}>
              <img
                alt="payment"
                src={mastercard}
                height={'12px'}
                title="payment"
                loading="eager"
              />
            </LazyLoad>
          </div>
        </div>
      )}
    </div>
  );
}

export default CartBottomNotification;
