import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  HIDE_LOGIN_POPUP,
  SHOW_LOGIN_POPUP,
  SHOW_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_TOASTER,
  HIDE_TOASTER,
  HIDE_LOCATION_POPUP,
  SHOW_LOCATION_POPUP,
  SET_SITE_LANGUAGE,
  HIDE_CART_NOTIFICATION,
  SHOW_CART_NOTIFICATION,
  SHOW_AREAS_LIST,
  HIDE_AREAS_LIST,
  HIDE_MOBILE_NAV,
  SHOW_MOBILE_NAV,
  SHOW_WHATSAPP_ICON,
  HIDE_WHATSAPP_ICON,
} from './actionTypes';

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = (layout) => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = (width) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeSidebarTheme = (theme) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeSidebarThemeImage = (themeimage) => ({
  type: CHANGE_SIDEBAR_THEME_IMAGE,
  payload: themeimage,
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = (topbarTheme) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const showRightSidebarAction = (isopen) => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
});

export const hideLoginPopupAction = () => ({
  type: HIDE_LOGIN_POPUP,
});
export const setLanguageAction = (lang) => ({
  type: SET_SITE_LANGUAGE,
  payload: lang,
});
export const showLoginPopupAction = () => ({
  type: SHOW_LOGIN_POPUP,
});

export const hideLocationPopupAction = () => ({
  type: HIDE_LOCATION_POPUP,
});
export const showLocationPopupAction = () => ({
  type: SHOW_LOCATION_POPUP,
});

export const hideToasterAction = () => ({
  type: HIDE_TOASTER,
});
export const showToasterAction = ({ type, data, position }) => ({
  type: SHOW_TOASTER,
  payload: { type, data, position },
});

export const showSidebar = (isopen) => ({
  type: SHOW_SIDEBAR,
  payload: isopen,
});

export const toggleLeftmenu = (isopen) => ({
  type: TOGGLE_LEFTMENU,
  payload: isopen,
});

export const hideCartNotificationAction = () => ({
  type: HIDE_CART_NOTIFICATION,
});
export const showCartNotificationAction = () => ({
  type: SHOW_CART_NOTIFICATION,
});

export const hideAreasListAction = () => ({
  type: HIDE_AREAS_LIST,
});
export const showAreasListAction = () => ({
  type: SHOW_AREAS_LIST,
});

export const hideMobileNavAction = () => ({
  type: HIDE_MOBILE_NAV,
});

export const showMobileNavAction = () => ({
  type: SHOW_MOBILE_NAV,
});

export const showWhatsappIcon = () => ({
  type: SHOW_WHATSAPP_ICON,
});

export const hideWhatsappIcon = () => ({
  type: HIDE_WHATSAPP_ICON,
});
