import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

// Import Routes all
import { publicRoutes, AuthProtectedRoute } from './routes';

// Import all middleware
import Authmiddleware from './routes/route';

// layouts Format
import NonAuthLayout from './components/NonAuthLayout';
import Preloader from 'components/elements/Preloader';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-responsive-modal/styles.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './assets/css/main.css';
import './assets/css/custom.css';
import './assets/sass/custom.scss';

import { Notification } from 'components/Common/Notification';
import LoginPopup from 'pages/user/LoginPopup';
import {
  getFavouriteItems,
  getPagesHome,
  getUser,
  setLanguageAction,
} from 'store/actions';
import { getSiteSettings } from 'store/settings/actions';
import CartBottomNotification from 'components/elements/cartBottomNotification';
import BannerLoader from 'components/Common/loaders/BannerLoader';
import UesWindowScrollTop from 'components/hooks/uesWindowScrollTop';
import MobileNav from 'components/layout/MobileNav';
import storage from 'util/localStorage';
import LandingPage from 'pages/LandingPages';
import { TOKEN_PREFIX } from 'helpers/api_methods';
import WhatsAppIcon from 'components/elements/whatsAppIcon';

const App = () => {
  const token = localStorage.getItem(TOKEN_PREFIX);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    isLoginPopup,
    cartNoti,
    favourites,
    homePage,
    siteSettings,
    isWhatsapp,
  } = useSelector((state) => ({
    isLoginPopup: state.Layout.isLoginPopup,
    cartNoti: state.Layout.cartNoti,
    favourites: state.Cart.favourites,
    homePage: state?.LayoutPages.homePage,
    siteSettings: state?.SiteSettings.siteSettings,
    isWhatsapp: state.Layout.isWhatsapp,
  }));

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getUser());
    }
  }, [token]);

  useEffect(() => {
    dispatch(setLanguageAction('en'));
  }, []);

  useEffect(() => {
    dispatch(getSiteSettings());
  }, []);

  useEffect(() => {
    if (isLoginPopup === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isLoginPopup]);

  useEffect(() => {
    dispatch(getFavouriteItems(1, 20));
  }, [dispatch]);

  useEffect(() => {
    const favIds = favourites?.favourites?.map((fav) => fav.product?._id);
    if (favIds?.length >= 1) {
      storage.set('user_favourite', favIds);
    }
  });

  useEffect(() => {
    if (siteSettings?.setup?.isCustomHomePage) {
      dispatch(getPagesHome());
    }
  }, [siteSettings?.setup]);

  const theme = siteSettings?.setup?.theme;

  const handlePublicRoutes = () => {
    if (
      siteSettings?.setup?.isCustomHomePage &&
      homePage?.sections &&
      homePage?.sections[0]?._id
    ) {
      const modifiedRoute = publicRoutes?.map((route) =>
        route?.path === '/' ? { ...route, component: LandingPage } : route,
      );
      return modifiedRoute;
    } else {
      return publicRoutes;
    }
  };
  // console.log('cartNoti', cartNoti);

  // --primary-colors: #22b3be;
  // --secondary-color: #ed475f;
  return (
    <React.Fragment>
      <style>
        {`
       :root {
        --primary-colors : ${
          theme?.primaryColor ? theme?.primaryColor : '#ffd700'
        };
        --secondary-color: ${
          theme?.secondaryColor ? theme?.secondaryColor : 'black'
        };
        
      }`}
      </style>
      <Notification />
      {isLoginPopup && <LoginPopup />}

      {loading ? (
        <Preloader />
      ) : (
        <Suspense
          fallback={
            <div>
              <BannerLoader />
              <BannerLoader />
              <BannerLoader />
            </div>
          }
        >
          <Router>
            <MobileNav />
            <UesWindowScrollTop />
            <CartBottomNotification />
            <Switch>
              {handlePublicRoutes().map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}
              {AuthProtectedRoute?.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
              <Route render={() => <Redirect to={{ pathname: '/404' }} />} />,
            </Switch>
            {isWhatsapp && <WhatsAppIcon cartNoti={cartNoti} />}
          </Router>
        </Suspense>
      )}
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
