import React from 'react';
import PropTypes from 'prop-types';
import TestimonialCard from './/TestMonialCards';
import { useCustomDevice } from 'components/hooks/useCustomDevice';

function Testimonials({ data }) {
  const web = (
    <div className="container">
      <div className="px-2">
        <div className="customerReview">
          <div>
            <div className="happy-customer-div mb-4">
              <h6 className="mb-10 swipe-find-text">HAPPY CUSTOMERS</h6>
            </div>
          </div>
          {/* <h4 className="font-w-600 ">Happy Customers</h4> */}
          <TestimonialCard data={data} />
        </div>
      </div>
    </div>
  );
  const mobile = web;

  return useCustomDevice(data?.platform, mobile, web);
}

export default Testimonials;

Testimonials.propTypes = {
  data: PropTypes.any,
};
