import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_BY_CATEGORY,
  GET_PRODUCT_BY_CATEGORY_SUCCESS,
  GET_PRODUCT_BY_CATEGORY_FAIL,
  GET_RELATED_PRODUCT,
  GET_RELATED_PRODUCT_SUCCESS,
  GET_RELATED_PRODUCT_FAIL,
  GET_RECENT_PRODUCT,
  GET_RECENT_PRODUCT_FAIL,
  GET_RECENT_PRODUCT_SUCCESS,
  GET_PRODUCT_REVIEWS,
  GET_PRODUCT_REVIEWS_FAIL,
  GET_PRODUCT_REVIEWS_SUCCESS,
  ADD_PRODUCT_REVIEW,
  ADD_PRODUCT_REVIEW_FAIL,
  ADD_PRODUCT_REVIEW_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  products: {
    page: 1,
    products: [],
    total: 0,
  },
  productList: [],
  relatedProduct: [],
  recentProduct: [],
  productDetail: {},
  error: {},
  loading: false,
  relatedProductLoad: false,
  recentProductLoad: false,
  productReview: {},
  reviewList: {},
  reviewLoading: false,
};

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
    case GET_PRODUCT_DETAIL:
    case GET_PRODUCT_BY_CATEGORY:
    case ADD_PRODUCT_REVIEW:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_REVIEWS:
      return {
        ...state,
        reviewLoading: true,
      };
    case GET_RELATED_PRODUCT:
      return {
        ...state,
        relatedProductLoad: true,
      };
    case GET_RECENT_PRODUCT:
      return {
        ...state,
        recentProductLoad: true,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        productList: action.payload.products,
        loading: false,
      };
    case GET_PRODUCT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };

    case GET_PRODUCTS_FAIL:
    case GET_PRODUCT_BY_CATEGORY_FAIL:
    case ADD_PRODUCT_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: action.payload,
        loading: false,
      };

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_RELATED_PRODUCT_SUCCESS:
      return {
        ...state,
        relatedProduct: action.payload,
        relatedProductLoad: false,
      };

    case GET_RELATED_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        relatedProductLoad: false,
      };
    case GET_RECENT_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        recentProductLoad: false,
      };

    case GET_RECENT_PRODUCT_SUCCESS:
      return {
        ...state,
        recentProduct: action.payload,
        recentProductLoad: false,
      };
    case ADD_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        productReview: action.payload,
        loading: false,
      };
    case GET_PRODUCT_REVIEWS_FAIL:
      return {
        ...state,
        error: action.payload,
        reviewLoading: false,
      };
    case GET_PRODUCT_REVIEWS_SUCCESS:
      return {
        ...state,
        reviewList: action.payload,
        reviewLoading: false,
      };

    default:
      return state;
  }
};

export default Products;
