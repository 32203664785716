import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useCloudinary from 'components/hooks/useCloudinaryImage';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import { useCustomDevice } from 'components/hooks/useCustomDevice';
import usePrice from 'components/hooks/usePrice';
import { useCallToAction } from 'components/hooks/useCallToAction';
import { useHistory } from 'react-router-dom';
import useWindow from 'components/hooks/useWindow';
import { Markup } from 'interweave';
import LazyLoad from 'react-lazyload';
import useCartActions from 'components/hooks/useCartAction';
import { getLocalCart } from 'store/actions';
import { TOKEN_PREFIX } from 'helpers/api_methods';
import PersonalizationModal from '../../../components/elements/CartPersonalizationModal'; // Assuming the path is correct

function WideContent({ data, isAttachedPage }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useWindow();
  const token = localStorage.getItem(TOKEN_PREFIX);

  const [isOpen, setIsOpen] = useState({ status: false });

  const {
    layoutPages,
    homePage,
    siteSettings,
    addOrRemoveLoad,
    productDetail,
  } = useSelector((state) => ({
    layoutPages: state.LayoutPages.layoutPages,
    homePage: state.LayoutPages.homePage,
    siteSettings: state?.SiteSettings.siteSettings,
    addOrRemoveLoad: state.Cart.addOrRemoveLoad,
    productDetail: state.Products.productDetail.product,
  }));

  const handlePageProductData = () => {
    if (siteSettings?.setup?.isCustomHomePage && location.pathname === '/') {
      return homePage?.product;
    } else return layoutPages?.product;
  };
  const product = handlePageProductData();

  const { stock } = usePrice(product);

  const handleCTAProduct = () => {
    if (isAttachedPage && layoutPages?.isDynamicProduct) {
      return productDetail;
    }
    return product;
  };

  const handleCTA = (type) =>
    useCallToAction(
      handleCTAProduct(),
      dispatch,
      type,
      stock,
      history,
      setIsOpen,
    );

  const handleCart = (personalization) => {
    const options = {
      product: product?._id,
      varient: product?.hasVarients ? product?.varients[0]?._id : '',
      quantity: 1,
      operation: 'add',
      personalization,
    };

    if (stock >= 1) {
      useCartActions(options, dispatch, { ...product, stock: stock }, true);
      if (!token) {
        dispatch(getLocalCart());
      }
    }
  };

  const web = (
    <div
      className={`wideContent h-100 ${
        data?.images && data?.images[0]?.url && data?.imagePosition
      }`}
      style={{ backgroundColor: data?.bgColor }}
    >
      <div
        className={`content  ${
          data?.images && data?.images[0]?.url && 'withImg'
        }`}
        style={{
          textAlign: data?.textAlign,
          alignItems: data?.textAlign,
        }}
      >
        <h4 className="mb-3 font-w-600" style={{ color: data?.fgColor }}>
          {data?.title}
        </h4>
        {data?.description && (
          // <p style={{ color: "#000" }}>{data?.description}</p>
          <Markup content={data?.description} />
        )}

        {data?.callToAction?.isRequired && data?.callToAction?.text && (
          <button
            className="btn d-flex align-items-center justify-content-center"
            onClick={() => handleCTA(data?.callToAction?.action)}
          >
            {data?.callToAction?.text}
            {addOrRemoveLoad && <FaSpinner className="mx-2 spinner" />}
          </button>
        )}
      </div>
      {data?.images?.length >= 1 && (
        <div className="contentImage">
          <LazyLoad height={200}>
            <img
              src={useCloudinary(
                data?.images[0]?.url ?? '',
                isMobile ? 'w_1000' : 'w_800',
              )}
              height={'100%'}
              width={'100%'}
              alt="wide-content-image"
              loading="lazy"
            />
          </LazyLoad>
        </div>
      )}
    </div>
  );

  const mobile = web;

  return (
    <>
      {useCustomDevice(data?.platform, mobile, web)}

      <PersonalizationModal
        handleCart={handleCart}
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
    </>
  );
}

export default WideContent;

WideContent.propTypes = {
  data: PropTypes.object,
  isAttachedPage: PropTypes.bool,
};
