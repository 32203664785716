import React, { useState } from 'react';
import usePrice from 'components/hooks/usePrice';
import PropTypes from 'prop-types';
import useCloudinary from 'components/hooks/useCloudinaryImage';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { useCustomDevice } from 'components/hooks/useCustomDevice';
import { useCallToAction } from 'components/hooks/useCallToAction';
import useWindow from 'components/hooks/useWindow';
import { Markup } from 'interweave';
import LazyLoad from 'react-lazyload';
import PersonalizationModal from '../../../components/elements/CartPersonalizationModal';
import useCartActions from 'components/hooks/useCartAction';
import { TOKEN_PREFIX } from 'helpers/api_methods';

function Highlight({ data, isAttachedPage }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = useWindow();
  const token = localStorage.getItem(TOKEN_PREFIX);

  const [isOpen, setIsOpen] = useState({ status: false });
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false });
  };

  const {
    layoutPages,
    homePage,
    siteSettings,
    addOrRemoveLoad,
    productDetail,
  } = useSelector((state) => ({
    layoutPages: state.LayoutPages.layoutPages,
    homePage: state.LayoutPages.homePage,
    siteSettings: state?.SiteSettings.siteSettings,
    addOrRemoveLoad: state.Cart.addOrRemoveLoad,
    productDetail: state.Products.productDetail.product,
  }));

  const handlePageProductData = () => {
    if (siteSettings?.setup?.isCustomHomePage && location.pathname === '/') {
      return homePage?.product;
    } else return layoutPages?.product;
  };

  const product = handlePageProductData();

  const { stock } = usePrice(product);

  const handleCTAProduct = () => {
    if (isAttachedPage && layoutPages?.isDynamicProduct) {
      return productDetail;
    }
    return product;
  };

  const handleCTA = (type) =>
    useCallToAction(
      handleCTAProduct(),
      dispatch,
      type,
      stock,
      history,
      setIsOpen,
    );

  const handleText = (type) => {
    switch (type) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      default:
        return type;
    }
  };

  const handleCart = (personalization) => {
    const options = {
      product: product?._id,
      varient: product?.hasVarients ? product?.varients[0]?._id : '',
      quantity: 1,
      operation: 'add',
      personalization,
    };

    if (stock >= 1) {
      useCartActions(options, dispatch, { ...product, stock: stock }, true);
      if (!token) {
        dispatch(getLocalCart());
      }
    }
  };

  const web = (
    <div className="">
      <div className="mx-2" style={{ backgroundColor: data?.bgColor }}>
        <div className="mx-5 sm:mx-0">
          <div className="d-flex-center-center highlight">
            <div className="content" style={{ textAlign: data?.textAlign }}>
              <h4 className="font-w-700" style={{ color: data?.fgColor }}>
                {data?.title}
              </h4>
              <Markup
                className="highlight-content"
                content={data?.description}
              />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: handleText(data?.textAlign),
                }}
              >
                {data?.callToAction?.isRequired && data?.callToAction?.text && (
                  <button
                    className="btn btn-custom d-flex align-items-center justify-content-center new-ui-btn-section"
                    onClick={() => handleCTA(data?.callToAction?.action)}
                  >
                    {data?.callToAction?.text}{' '}
                    {addOrRemoveLoad && <FaSpinner className="mx-2 spinner" />}
                  </button>
                )}
              </div>
            </div>
            {data?.images[0]?.url && (
              <div className="image-wrapper">
                <LazyLoad height={200}>
                  <img
                    src={useCloudinary(
                      data?.images[0]?.url ?? '',
                      isMobile ? 'w_1000' : 'w_700',
                    )}
                    height={'100%'}
                    width={'100%'}
                    loading="lazy"
                    alt="highlight-main-image"
                  />
                </LazyLoad>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const mobile = web;

  return (
    <>
      {useCustomDevice(data?.platform, mobile, web)}

      <PersonalizationModal
        handleCart={handleCart}
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
    </>
  );
}

export default Highlight;

Highlight.propTypes = {
  data: PropTypes.any,
  isAttachedPage: PropTypes.bool,
};
