import React, { useEffect, useState } from 'react';

import loading from '../../assets/imgs/logos/Artboard 8.svg';
import loading2 from '../../assets/imgs/logos/Artboard 9.svg';

import LazyLoad from 'react-lazyload';

const Preloader = () => {
  const [currentImage, setCurrentImage] = useState(loading);

  useEffect(() => {
    // Change the image after 2 seconds
    const interval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === loading ? loading2 : loading,
      );
    }, 650);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center">
              <LazyLoad>
                <img
                  src={currentImage}
                  alt="loading"
                  height={'140px'}
                  className="p-1 tada-animation"
                  width={140}
                  title="Loading-klaybox"
                  loading="eager"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
