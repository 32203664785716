import useLangSwitch from 'components/hooks/useLanguageSwicth';
import usePrice from 'components/hooks/usePrice';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedLoader from 'components/Common/loaders/CustomizedLoader';
import useCartActions from 'components/hooks/useCartAction';
import { getLocalCart } from 'store/actions';
import useCloudinary from 'components/hooks/useCloudinaryImage';
import LazyLoad from 'react-lazyload';
import { TOKEN_PREFIX } from 'helpers/api_methods';

function CartItemList({ item, noti }) {
  const dispatch = useDispatch();
  const product = item?.product;

  const { currencyCode, price } = usePrice(product);

  const [prodId, setProdId] = useState('');

  const { loading } = useSelector((state) => ({
    loading: state.Cart.addOrRemoveLoad,
  }));
  const token = localStorage.getItem(TOKEN_PREFIX);

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang);

  const deleteFromCart = (item) => {
    setProdId(item?.product?._id);
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: 'substract',
      quantity: item?.quantity ?? 1,
    };
    useCartActions(options, dispatch, product);
    if (!token) {
      dispatch(getLocalCart());
    }
  };

  const currentVarient = item?.product?.varients?.filter(
    (i) => i._id === item?.varient,
  )[0];

  const handleStockText = () => {
    if (item?.isOutOfStock === true) {
      if (currentVarient?.stock <= 0) {
        return 'Out of Stock';
      } else if (item?.quantity > currentVarient?.stock) {
        return 'Only ' + currentVarient?.stock + ' left';
      }
    } else return '';
  };
  const removeSpace = language?.name?.replace(/ /g, '-');

  return (
    <>
      {loading && prodId === product?._id ? (
        <CustomizedLoader count={1} style={{ height: '70px' }} />
      ) : (
        <>
          {item?.product !== null && (
            <li className="d-flex align-items-center justify-content-between flex-nowrap">
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className={`shopping-cart-img w:50px ${
                    noti ? 'mx-3' : 'mx-0'
                  }`}
                >
                  <Link
                    style={{ display: 'block' }}
                    to={`/products/${removeSpace}`}
                  >
                    <LazyLoad height={200}>
                      <img
                        alt="product"
                        title="product"
                        loading="eager"
                        src={useCloudinary(
                          product.images[0]?.url,
                          'h_350,w_350',
                        )}
                        className="h-100 w-100 rounded"
                      />
                    </LazyLoad>
                  </Link>
                </div>
                <div className={'shopping-cart-title mx-3'}>
                  <h4
                    style={{
                      maxWidth: noti ? '100%' : '100%',
                      fontWeight: '400',
                    }}
                  >
                    <Link
                      to={`/products/${removeSpace}`}
                      className="text-black"
                    >
                      <span>
                        {handleStockText() && (
                          <p className="text-danger pb-1">
                            ! {handleStockText()}
                          </p>
                        )}
                        {language?.name}
                        {item?.varientValue && (
                          <span>
                            ({item?.varientValue}&nbsp;
                            {item?.varientUnit})
                          </span>
                        )}
                      </span>
                    </Link>
                  </h4>
                  <h3 className="text-black" style={{ fontWeight: '500' }}>
                    <span>{item?.quantity} × </span>
                    {currencyCode}
                    {item?.offerPrice ? item?.offerPrice : item?.price ?? price}
                  </h3>
                </div>
              </div>
              {!noti && (
                <div className="shopping-cart">
                  <Link to="#!" onClick={() => deleteFromCart(item)}>
                    <span>
                      <i className="fi-rs-cross-small text-black font-lg"></i>
                    </span>
                  </Link>
                </div>
              )}
            </li>
          )}
        </>
      )}
    </>
  );
}

export default CartItemList;

CartItemList.propTypes = {
  item: PropTypes.any,
  noti: PropTypes.bool,
};
