import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import MyRating from 'components/productUtil/Rating';
import useCloudinary from 'components/hooks/useCloudinaryImage';
import { useCustomDevice } from 'components/hooks/useCustomDevice';
import LazyLoad from 'react-lazyload';
import BannerLoader from 'components/Common/loaders/BannerLoader';
import useWindow from 'components/hooks/useWindow';
import Masonry from 'react-masonry-css';

const TestimonialCard = ({ data }) => {
  const { isMobile } = useWindow();

  const { loading } = useSelector((state) => ({
    loading: state.Banner.loading,
  }));
  // console.log(data);
  const breakpointColumnsObj = {
    default: 4,
    1100: 4,
    700: 2,
  };

  const testimonialCards = (
    <div className="row">
      {loading ? (
        <div className="col">
          <BannerLoader />
        </div>
      ) : (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
          style={{ display: 'flex', width: '100%' }}
        >
          {data?.testimonials.map((ban, key) => (
            <div
              className="col-xl-12 col-md-12 col-sm-6 testimonial-card-col"
              key={key}
            >
              <div className="card h-100 testimonial-card">
                <div className="card-header testimonial-card-header">
                  {ban?.image?.url && (
                    <LazyLoad height={70}>
                      <img
                        className=""
                        src={useCloudinary(
                          ban?.image?.url ?? '',
                          isMobile ? 'w_300' : 'w_800',
                        )}
                        loading={'lazy'}
                        alt="testimonial"
                      />
                    </LazyLoad>
                  )}
                </div>
                <div className="card-body new-cart-body ">
                  <MyRating
                    initialRating={ban?.rating}
                    quiet={true}
                    readonly={true}
                  />
                  <p className="card-text new-text-card">
                    {ban?.message?.length >= 200
                      ? ban?.message?.slice(0, 200) + '...'
                      : ban?.message}
                  </p>
                  <h5 className="card-title new-card-title">{ban?.name}</h5>
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      )}
    </div>
  );

  return useCustomDevice(data?.platform, testimonialCards, testimonialCards);
};

export default TestimonialCard;

TestimonialCard.propTypes = {
  data: PropTypes.any,
};
