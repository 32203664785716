import { call, put, takeEvery } from 'redux-saga/effects';

// Crypto Redux States
import {
  ADD_PRODUCT_REVIEW,
  GET_PRODUCTS,
  GET_PRODUCT_BY_CATEGORY,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_REVIEWS,
  GET_RECENT_PRODUCT,
  GET_RELATED_PRODUCT,
} from './actionTypes';
import {
  getProductsSuccess,
  getProductsFail,
  getProductDetailSuccess,
  getProductDetailFail,
  getProductsByCategorySuccess,
  getProductsByCategoryFail,
  getRelatedProductsSuccess,
  getRelatedProductsFail,
  getRecentProductsSuccess,
  getRecentProductsFail,
  addProductReviewSuccess,
  addProductReviewFail,
  getProductReviewsSuccess,
  getProductReviewsFail,
} from './actions';
import { get, post, ApiPut, del, patch } from 'helpers/api_methods';
import { getOrderDetailsFail, getOrderDetailsSuccess } from 'store/actions';

//products
function getProductsAPi({ searchText, page, custom, sort, limit }) {
  return get(
    `/product/user/custom/${custom ? custom : 'all'}?page=${
      page ? page : 1
    }&sort=${sort ? sort : ''}&limit=${limit ? limit : 10}&search=${
      searchText ? searchText : ''
    }`,
  );
}

function getProductsByCategoryAPi({ searchText, page, custom, sort, limit }) {
  return get(
    `/product/user/category/name/${custom ? custom : ''}?page=${
      page ? page : 1
    }&sort=${sort ? sort : ''}&limit=${limit ? limit : 10}&search=${
      searchText ? searchText : ''
    }`,
  );
}

const getProductDetailsAPi = (productName) => {
  return get(`/product/user/single/name/${productName}`);
};

const getRelatedProductAPi = (productName) => {
  return get(`/product/user/related/name/${productName}`);
};

const fetchRecentProductApi = () => {
  return get(`/recent-view/user`);
};

const addProductReviewApi = ({ review }) => {
  return post(`/review/user/order/new`, review);
};

function fetchOrderDetailApi(orderId) {
  return get(`/order/user/single/${orderId}`);
}

const getProductReviewsApi = ({ productId, page, limit }) => {
  return get(
    `/review/user/product/${productId}?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }`,
  );
};

//products
function* fetchProducts({ payload }) {
  try {
    const response = yield call(getProductsAPi, payload);
    yield put(getProductsSuccess(response));
  } catch (error) {
    yield put(getProductsFail(error));
  }
}
function* fetchProductsByCategory({ payload }) {
  try {
    const response = yield call(getProductsByCategoryAPi, payload);
    yield put(getProductsByCategorySuccess(response));
  } catch (error) {
    yield put(getProductsByCategoryFail(error));
  }
}

function* fetchProductDetail({ productName }) {
  try {
    const response = yield call(getProductDetailsAPi, productName);
    yield put(getProductDetailSuccess(response));
  } catch (error) {
    yield put(getProductDetailFail(error));
  }
}

//related product
function* fetchRelatedProduct({ payload }) {
  try {
    const response = yield call(getRelatedProductAPi, payload);
    if (response?.length) {
      yield put(getRelatedProductsSuccess(response));
    }
  } catch (error) {
    yield put(getRelatedProductsFail(error));
  }
}

//fetchRecentProduct product
function* fetchRecentProduct({ payload }) {
  try {
    const response = yield call(fetchRecentProductApi, payload);
    if (response?.total > 0) {
      yield put(getRecentProductsSuccess(response));
    }
  } catch (error) {
    yield put(getRecentProductsFail(error));
  }
}

function* getProductReviews({ payload }) {
  try {
    const response = yield call(getProductReviewsApi, payload);
    if (response) {
      yield put(getProductReviewsSuccess(response));
    }
  } catch (error) {
    yield put(getProductReviewsFail(error));
  }
}

//addProductReview product
function* addProductReview({ payload }) {
  try {
    const response = yield call(addProductReviewApi, payload);
    if (response) {
      yield put(addProductReviewSuccess(response));
      try {
        const response2 = yield call(fetchOrderDetailApi, response?.order);
        yield put(getOrderDetailsSuccess(response2));
      } catch (error) {
        yield put(getOrderDetailsFail(error));
      }
      payload.handleClose();
    }
  } catch (error) {
    yield put(addProductReviewFail(error));
  }
}

function* productsSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts);
  yield takeEvery(GET_PRODUCT_BY_CATEGORY, fetchProductsByCategory);
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail);
  yield takeEvery(GET_RELATED_PRODUCT, fetchRelatedProduct);
  yield takeEvery(GET_RECENT_PRODUCT, fetchRecentProduct);
  yield takeEvery(GET_PRODUCT_REVIEWS, getProductReviews);
  yield takeEvery(ADD_PRODUCT_REVIEW, addProductReview);
}

export default productsSaga;
