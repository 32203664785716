import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import UseImageUploader from 'components/Common/imageUploader/useImageUploader';
import { useForm } from 'react-hook-form';
import { showToasterAction } from 'store/actions';
import useWindow from 'components/hooks/useWindow';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function CompleteOrder({ handleCart, stock, onCloseClick }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useWindow();

  const { loading, productDetail, addOrRemoveLoad } = useSelector((state) => ({
    productDetail: state.Products.productDetail,
    addOrRemoveLoad: state.Cart.addOrRemoveLoad,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      text: '',
      color: '',
    },
  });

  const textValue = watch('text');
  const colorValue = watch('color');

  const [imagesPreview, setImagesPreview] = useState([]);
  const [uploadLoad, setUploadLoad] = useState(false);
  const [loadingBuyNow, setLoadingBuyNow] = useState(false);

  const resetState = () => {
    reset({
      text: '',
      color: '',
    });
    setImagesPreview([]);
  };

  const customizationOptions = productDetail?.product?.customizationOptions;

  const handleCheckImageValidation = () => {
    if (customizationOptions?.options?.includes('image')) {
      if (imagesPreview?.length === customizationOptions?.imageCount) {
        return true;
      }
      dispatch(
        showToasterAction({
          data: {
            title: 'Image Validation',
            message: `${customizationOptions?.imageCount} pictures must be uploaded for this particular product.`,
          },
          type: 'info',
        }),
      );
      return false;
    } else return true;
  };

  const handleBuyNow = () => {
    setLoadingBuyNow(true);

    const personalizationObject = {
      color: colorValue || '',
      text: textValue || '',
      images: imagesPreview || [],
    };

    if (handleCheckImageValidation() === true) {
      handleCart(personalizationObject);

      setTimeout(() => {
        resetState();
        if (onCloseClick) {
          onCloseClick();
        }
        setLoadingBuyNow(false);
      }, 2000);
      history.push('/cart');
    } else {
      setLoadingBuyNow(false);
    }
  };

  const handleUpdateUserDetails = (values, e) => {
    e.preventDefault();
    setUploadLoad(true);

    const personalizationObject = {
      color: values?.color || '',
      text: values?.text || '',
      images: imagesPreview || [],
    };

    if (handleCheckImageValidation() === true) {
      handleCart(personalizationObject);

      setTimeout(() => {
        resetState();
        if (onCloseClick) {
          onCloseClick();
        }
        setUploadLoad(false);
      }, 2000);
    } else {
      setUploadLoad(false);
    }
  };

  const handleCartFunction = () => {
    handleCart();
  };

  return (
    <div>
      <div className="  mt-2">
        <div className="">
          <form
            name="enq"
            className="new-style"
            onSubmit={handleSubmit((value, e) =>
              handleUpdateUserDetails(value, e),
            )}
          >
            <div className="row">
              {customizationOptions?.options?.includes('text') && (
                <div className="form-group col-md-12">
                  <input
                    className="form-control"
                    name="text"
                    type="text"
                    placeholder="Enter your superhero character"
                    {...register('text', {
                      required: {
                        value: true,
                        message: 'Please provide text',
                      },
                    })}
                  />
                  {errors?.text && (
                    <span className="text-danger mx-3">
                      {errors?.text.message}
                    </span>
                  )}
                </div>
              )}

              {customizationOptions?.options?.includes('color') && (
                <div className="form-group col-md-12">
                  <input
                    className="form-control"
                    name="color"
                    type="text"
                    placeholder="Paste Your Color"
                    {...register('color', {
                      required: {
                        value: true,
                        message: 'Please provide color',
                      },
                    })}
                  />
                  {errors?.color && (
                    <span className="text-danger mx-3">
                      {errors?.color.message}
                    </span>
                  )}
                </div>
              )}

              {/* <div className="form-group col-md-12">
                <ChromePicker
                  width="180px"
                  className="me-3"
                  onChange={(color) => setPrimaryColor(color)}
                  color={primaryColor}
                />
              </div> */}

              {customizationOptions?.options?.includes('image') && (
                <div className="form-group col-md-12">
                  <UseImageUploader
                    imageSize={15}
                    imagesPreview={imagesPreview}
                    setImagesPreview={setImagesPreview}
                    isMultiple={
                      imagesPreview?.length <
                      productDetail?.product?.customizationOptions?.imageCount
                    }
                  />
                </div>
              )}
              {customizationOptions?.options?.length > 0 && (
                <>
                  <div className="col-md-6 d-flex align-items-center justify-content-start mb-2">
                    <button
                      type="submit"
                      className="btn btn-sm btn-fill-out font-weight-bold  font-size-14 fw-bold save-changes-btn"
                      name="submit"
                      value="Submit"
                      style={{
                        borderRadius: '0',
                        width: '100%',
                      }}
                      onClick={handleBuyNow}
                    >
                      Buy Now
                      {(loading || addOrRemoveLoad || loadingBuyNow) && (
                        <FaSpinner className="mx-2 spinner" />
                      )}
                    </button>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-start mb-2">
                    <button
                      type="submit"
                      className="btn btn-sm btn-fill-out submit font-weight-bold  font-size-14 fw-bold save-changes-btn buy-btn"
                      name="submit"
                      value="Submit"
                      style={{
                        borderRadius: '0',
                        width: '100%',
                        marginBottom: isMobile ? '10px' : '0px',
                      }}
                    >
                      Add to Cart
                      {(loading || addOrRemoveLoad || uploadLoad) && (
                        <FaSpinner className="mx-2 spinner" />
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </form>

          {customizationOptions?.options?.length === 0 && (
            <div className="col-md-12 d-flex align-items-center justify-content-start">
              <button
                type="submit"
                className="btn btn-sm btn-fill-out submit font-weight-bold  font-size-14 fw-bold save-changes-btn "
                name="submit"
                value="Submit"
                disabled={stock <= 0}
                style={{ borderRadius: '0', width: '100%' }}
                onClick={handleCartFunction}
              >
                Add to Cart
                {(loading || addOrRemoveLoad) && (
                  <FaSpinner className="mx-2 spinner" />
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompleteOrder;

CompleteOrder.propTypes = {
  handleCart: PropTypes.any,
  stock: PropTypes.any,
  onCloseClick: PropTypes.func,
};
