import { HiBars3 } from 'react-icons/hi2';
import useWindow from 'components/hooks/useWindow';
import { LogoSm, MainLogo } from 'assets/imgs/logos';

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import iconHeart from 'assets/imgs/theme/icons/icon-heart.svg';
import iconCart from 'assets/imgs/theme/icons/icon-cart.svg';
import iconUser from 'assets/imgs/theme/icons/icon-user.svg';
import {
  getCategories,
  getCollections,
  getFavouriteItems,
  getLocalCart,
  getQuicklist,
  getUserCart,
  logoutUser,
  showLoginPopupAction,
} from 'store/actions';
import { map } from 'lodash';
import CartItemList from 'components/elements/cartItemComp2';
import usePrice from 'components/hooks/usePrice';
import EmptyCartSvg from 'components/Common/emptyCart';
import { useLocalCartPrices } from 'components/hooks/useCartAction';
import useLangSwitch from 'components/hooks/useLanguageSwicth';
import LazyLoad from 'react-lazyload';

export const pathsThatNotShowInCheckout = [
  '/cart',
  '/checkout',
  '/checkout/login',
];

function Header() {
  const { isMobile } = useWindow();

  const [sideBarOpen, setSideBarOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const token = localStorage.getItem('klaybox_token');

  const {
    cartItems,
    userCart,
    company,
    favorites,
    localCart,
    user,
    layoutPages,
  } = useSelector((state) => ({
    user: state.Login.user,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    userCart: state.Cart.userCart,
    company: state?.SiteSettings.siteSettings?.company,
    favorites: state.Cart.favorites,
    layoutPages: state.LayoutPages.layoutPages,
  }));

  const handleLoginPopup = () => dispatch(showLoginPopupAction());

  const handleLogout = () => {
    dispatch(logoutUser(history));
  };

  useEffect(() => {
    dispatch(getUserCart());
    dispatch(getCategories('', 'all'));
    dispatch(getFavouriteItems(1, 10));
    dispatch(getQuicklist(1, 10));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCollections());
  }, [dispatch]);

  const { currencyCode } = usePrice();
  const language = useLangSwitch(
    layoutPages?.product?.primaryLang,
    layoutPages?.product?.secondaryLang,
  );

  const handleLoginFun = (path) => {
    if (path) {
      history.push(path);
    }
  };

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems;
    } else if (localCart?.length >= 1) {
      return localCart?.filter((i) => i?.quantity !== 0);
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart());
    }
  }, [token]);

  const { itemTotal } = useLocalCartPrices();

  return (
    <>
      <div className="container">
        {/* <img
          src={bgElement}
          className="position-absolute  bg-element"
          alt="background"
          loading="lazy"
        /> */}
        {/* <div className="d-flex p-4 header-wrapper ">
                    <div className="" style={{ width: "100px", height: "100px" }}>
                        <img src={logo} height="100%" width={"100%"} alt="logo" loading="lazy" />
                    </div>

                    {isMobile ? <div className="d-flex align-items-center mx-3 page-nav-link">
                        <HiBars3 color="#7B0063" onClick={() => setSideBarOpen(true)} size={'1.8rem'} />
                    </div> : <div className="d-flex align-items-center mx-5 page-nav-link">
                        <Link>Home</Link>
                        <Link>About us</Link>
                        <Link>Contact</Link>
                        <Link className="red">Buy Now!</Link>
                    </div>}
                </div>

                <div
                    className={
                        sideBarOpen
                            ? "mobile-header-active mobile-header-wrapper-style sidebar-visible"
                            : "mobile-header-active mobile-header-wrapper-style"
                    }
                >
                    <div className="mobile-header-wrapper-inner">
                        <div className="mobile-header-top">
                            <div className="mobile-header-logo">
                                <div className="" style={{ width: "100px", height: "100px" }}>
                                    <img src={logo} height="100%" width={"100%"} alt="logo" loading="lazy" />
                                </div>
                            </div>
                            <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                                <button
                                    className="close-style search-close"
                                    onClick={() => setSideBarOpen(false)}
                                >
                                    <i className="icon-top"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>

                        </div>
                        <nav>
                            <div className="d-flex flex-column align-items-start mt-4 page-nav-link">
                                <Link>Home</Link>
                                <Link>About us</Link>
                                <Link>Contact</Link>
                                <Link className="red">Buy Now!</Link>
                            </div>
                        </nav>
                    </div>
                </div> */}

        <div className="header-middle header-middle-ptb-1">
          <div className="container">
            <div className="header-wrap d-flex justify-content-between">
              <div className="logo logo-width-2">
                <Link to="/">
                  <LazyLoad height={200}>
                    <img
                      src={
                        MainLogo
                        // company?.logo[0]?.url
                        //   ? useCloudinary(company?.logo[0]?.url, 'w_300')
                        //   : MainLogo
                      }
                      alt="logo"
                      className="fit-contain mx-4 logo-page-main"
                      title="logo"
                      loading="eager"
                    />
                  </LazyLoad>
                </Link>
              </div>
              <div className="header-right mx-4 w-fit">
                <div className="search-style-2 position-relative max-w:600px">
                  {isMobile ? (
                    <div className="d-flex align-items-center mx-3 page-nav-link">
                      <HiBars3
                        color="black"
                        onClick={() => setSideBarOpen(true)}
                        size={'1.8rem'}
                      />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center mx-5 page-nav-link">
                      <Link to={'/'}>Home</Link>
                      {/* <Link to={"/contact"}>About us</Link> */}
                      {/* <Link to={"/contact"}> Contact</Link> */}
                      <Link to={'/categroies'}> Categories</Link>
                      <Link
                        className="red"
                        to={{
                          pathname: '/products',
                          query: {
                            prod: 'all',
                          },
                        }}
                      >
                        Products
                      </Link>
                      <Link
                        to={{
                          pathname: '/pages',
                        }}
                      >
                        Pages
                      </Link>
                      {/* <Link
                        className="red"
                        to={`/products/${useRemoveSpace(language?.name)}`}
                      >
                        Buy Now!
                      </Link> */}
                    </div>
                  )}
                  <div
                    className={
                      sideBarOpen
                        ? 'mobile-header-active mobile-header-wrapper-style sidebar-visible'
                        : 'mobile-header-active mobile-header-wrapper-style'
                    }
                  >
                    <div className="mobile-header-wrapper-inner">
                      <div className="mobile-header-top">
                        <div className="mobile-header-logo">
                          <div
                            className=""
                            style={{ width: '100px', height: '100px' }}
                          >
                            <LazyLoad height={200}>
                              <img
                                src={MainLogo}
                                height="100%"
                                width={'100%'}
                                alt="logo"
                                loading="lazy"
                              />
                            </LazyLoad>
                          </div>
                        </div>
                        <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                          <button
                            className="close-style search-close"
                            onClick={() => setSideBarOpen(false)}
                          >
                            <i className="icon-top"></i>
                            <i className="icon-bottom"></i>
                          </button>
                        </div>
                      </div>
                      <nav>
                        <div className="d-flex flex-column align-items-start mt-4 page-nav-link">
                          <Link to={'/'}>Home</Link>
                          {/* <Link to={"/about"}>About us</Link> */}
                          <Link to={'/contact'}> Contact</Link>
                          <Link to={'/categroies'}> Categories</Link>
                          <Link to={'/pages'}> Pages</Link>
                          <Link
                            className="red"
                            to={{
                              pathname: '/products',
                              query: {
                                prod: 'all',
                              },
                            }}
                          >
                            Products
                          </Link>
                          {/* <Link
                            className="red"
                            to={`/products/${useRemoveSpace(language?.name)}`}
                          >
                            Buy Now!
                          </Link> */}
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="header-action-right">
                  <div className="header-action-2">
                    <div className="header-action-icon-2 ">
                      {/* <Link
                        to={'#!'}
                        onClick={() => handleLoginFun('/wishlist')}
                      >
                        <span>
                          <LazyLoad height={20}>
                            <img
                              className="svgInject"
                              alt="favorites"
                              src={iconHeart}
                              title="favorites"
                              loading="eager"
                            />
                          </LazyLoad>
                          {favorites?.total >= 1 ? (
                            <span className="pro-count blue FontCharlie">
                              {favorites?.total}
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                      </Link> */}
                      {/* <Link
                        to={'#!'}
                        onClick={() => handleLoginFun('/wishlist')}
                      >
                        <span className="lable FontCharlie text-black">
                          Wish List
                        </span>
                      </Link> */}
                    </div>

                    <div className="header-action-icon-2">
                      <Link to={'#!'} onClick={() => handleLoginFun('/cart')}>
                        <div className="mini-cart-icon">
                          <LazyLoad height={20}>
                            <img
                              alt="cart"
                              title="cart"
                              loading="eager"
                              src={iconCart}
                            />
                          </LazyLoad>
                          {handleCartItem()?.length >= 1 ? (
                            <span className="pro-count blue FontCharlie">
                              {handleCartItem()?.length}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </Link>

                      <Link to={'#!'} onClick={() => handleLoginFun('/cart')}>
                        <span className="lable FontCharlie text-black">
                          Cart
                        </span>
                      </Link>

                      <div className="cart-dropdown-wrap cart-dropdown-hm2">
                        {handleCartItem()?.length >= 1 ? (
                          <>
                            <ul>
                              {map(handleCartItem(), (item, key) => (
                                <Fragment key={key}>
                                  <CartItemList item={item} />
                                </Fragment>
                              ))}
                            </ul>

                            <div className="shopping-cart-footer">
                              <div className="shopping-cart-total">
                                {userCart?.shippingPrice ? (
                                  <p className="d-flex justify-content-between">
                                    Shipping
                                    <span className="">
                                      {currencyCode}{' '}
                                      {userCart?.shippingPrice ?? 0}
                                    </span>
                                  </p>
                                ) : (
                                  ''
                                )}
                                {userCart?.couponDiscount ? (
                                  <p className="d-flex justify-content-between">
                                    Coupon Discount
                                    <span className="">
                                      - {currencyCode}{' '}
                                      {userCart?.couponDiscount}
                                    </span>
                                  </p>
                                ) : (
                                  ''
                                )}
                                <h4>
                                  Total
                                  <span className="text-success">
                                    {currencyCode}{' '}
                                    {userCart?.totalPrice
                                      ? userCart?.totalPrice
                                      : itemTotal}
                                  </span>
                                </h4>
                              </div>
                              <div className="shopping-cart-button ">
                                <Link
                                  onClick={() => handleLoginFun('/cart')}
                                  to={'#!'}
                                >
                                  View cart
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : (
                          <EmptyCartSvg style={{ height: '200px' }} />
                        )}
                      </div>
                    </div>

                    <div className="header-action-icon-2">
                      {token ? (
                        <>
                          <Link to="/user-account">
                            <span>
                              <LazyLoad height={20}>
                                <img
                                  title="user"
                                  loading="eager"
                                  className="svgInject"
                                  alt="user"
                                  src={iconUser}
                                />
                              </LazyLoad>
                            </span>
                          </Link>
                          <Link to="/user-account">
                            <span className="FontCharlie">
                              {user?.name ? (
                                <span
                                  className="FontCharlie"
                                  style={{ textTransform: 'capitalize' }}
                                >
                                  {user?.name?.split(' ')[0]}
                                </span>
                              ) : (
                                <span className="lable ml-0 FontCharlie text-black">
                                  Account
                                </span>
                              )}
                            </span>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to="#!" onClick={handleLoginPopup}>
                            <span>
                              <LazyLoad height={20}>
                                <img
                                  className="svgInject"
                                  alt="user"
                                  src={iconUser}
                                  title="user"
                                  loading="eager"
                                />
                              </LazyLoad>
                            </span>
                          </Link>
                          <Link to="#!" onClick={handleLoginPopup}>
                            <span>
                              <span className="lable ml-0 FontCharlie text-black">
                                Login
                              </span>
                            </span>
                          </Link>
                        </>
                      )}
                      {token ? (
                        <div className="cart-dropdown-wrap FontCharlie cart-dropdown-hm2 account-dropdown">
                          <ul>
                            <li>
                              <Link to="/user-account">
                                <span>
                                  <i className="fi fi-rs-user mr-10"></i>
                                  My Account
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: '/user-account',
                                  state: { index: 2 },
                                }}
                              >
                                <span>
                                  <i className="fi fi-rs-location-alt mr-10"></i>
                                  Order Tracking
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: '/user-account',
                                  state: { index: 4 },
                                }}
                              >
                                <span>
                                  <i className="fi fi-rs-label mr-10"></i>
                                  My Address
                                </span>
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                to={'#!'}
                                onClick={() => handleLoginFun('/wishlist')}
                              >
                                <span>
                                  <i className="fi fi-rs-heart mr-10"></i>
                                  My Wish List
                                </span>
                              </Link>
                            </li> */}

                            <li>
                              <Link to="#!" onClick={handleLogout}>
                                <span>
                                  <i className="fi fi-rs-sign-out mr-10"></i>
                                  Sign out
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
