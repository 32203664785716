import React from 'react';
// import {
//   HiOutlineHome,
//   HiOutlineShoppingCart,
//   HiOutlineUserCircle,
//   HiOutlineViewGrid,
// } from 'react-icons/hi';
import { IoCart } from 'react-icons/io5';

import { IoMdHome } from 'react-icons/io';
// import { GoPersonFill } from 'react-icons/go';
import { IoPerson } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { showLoginPopupAction } from 'store/actions';
import { TOKEN_PREFIX } from 'helpers/api_methods';
import { IoMdHeart } from 'react-icons/io';

function MobileNav() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const token = localStorage.getItem(TOKEN_PREFIX);

  const { cartItems, localCart, isLoginPopup, cartNoti, mobileNav } =
    useSelector((state) => ({
      cartItems: state.Cart.cartItems,
      localCart: state.Cart.localCart,
      isLoginPopup: state.Layout.isLoginPopup,
      cartNoti: state.Layout.cartNoti,
      mobileNav: state.Layout.mobileNav,
    }));

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems;
    } else if (localCart?.length >= 1) {
      return localCart?.filter((i) => i?.quantity !== 0);
    } else {
      return [];
    }
  };

  const handleAuth = () => {
    if (token) {
      history.push('/user-account');
    } else {
      dispatch(showLoginPopupAction());
    }
  };

  return (
    <>
      {location.pathname === '/checkout' ? (
        <></>
      ) : (
        <div
          className={
            'MobileNav ' + `${mobileNav === false ? 'hideToBottom' : ''}`
          }
          // style={{
          //   boxShadow:
          //     isLoginPopup || cartNoti
          //       ? ''
          //       : 'rgb(149 157 165 / 20%) 0px -5px 24px',
          // }}
        >
          <Link to={'/'} className="text-gray mobile_nav_item">
            <IoMdHome className="mobile-nav-icons" />
            <span className="mobile-nav-text">Home</span>
          </Link>
          <Link
            to={{
              pathname: '/products',
              query: {
                prod: 'all',
              },
            }}
            className="text-gray mobile_nav_item"
          >
            <IoMdHeart className="mobile-nav-icons" />
            <span className="mobile-nav-text">Products</span>
          </Link>
          <Link
            to={'#!'}
            onClick={handleAuth}
            className="text-gray mobile_nav_item"
          >
            <IoPerson className="mobile-nav-icons" />
            <span className="mobile-nav-text">Account</span>
          </Link>
          <Link
            to={'/cart'}
            className="text-gray mobile_nav_item position-relative"
          >
            <IoCart className="mobile-nav-icons" />
            <span className="mobile-nav-text">Cart</span>
            {handleCartItem()?.length >= 1 && (
              <span
                className="pro-count text-white navIcon2 bg-darkGreen px-1"
                style={{ borderRadius: '50%' }}
              >
                {handleCartItem()?.length}
              </span>
            )}
          </Link>
        </div>
      )}
    </>
  );
}

export default MobileNav;
