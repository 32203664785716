import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import whasapp from 'assets/imgs/other/Whatsapp2.svg';
import { useSelector } from 'react-redux';
import { FRONT_END_URL } from 'helpers/api_methods';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function WhatsAppIcon({ cartNoti }) {
  const location = useLocation();
  const { company } = useSelector((state) => ({
    company: state?.SiteSettings.siteSettings?.company,
  }));

  const mobileNumber = company?.contactDetails?.mobile;

  const productValue = location?.pathname?.split('/');

  return (
    <div
      className="floting-whatsapp-icon"
      title="Get more information by Whatsapping our Customer Care team"
    >
      {productValue[1] === 'products' && productValue[2] ? (
        <a
          href={`https://api.whatsapp.com/send?phone=${mobileNumber}&text=${FRONT_END_URL}${location?.pathname}. %0aHi,%20I%20would%20like%20to%20know%20more%20about%20this%20product 🤩.`}
          className={`fixed_btn scroll-to-top show`}
          style={{
            bottom:
              cartNoti ||
              !location?.pathname === '/cart' ||
              !location?.pathname === '/checkout'
                ? '13rem'
                : '6rem',
          }}
          target="_blank"
          rel="noreferrer"
        >
          <div id="chat-btn" className="w_button">
            <LazyLoad height={200}>
              <img
                src={whasapp}
                title="whatsapp"
                loading="eager"
                alt="whatsapp"
              />
            </LazyLoad>
          </div>
        </a>
      ) : (
        <a
          href={`whatsapp://send?phone=${mobileNumber}&text=${FRONT_END_URL}`}
          className={`fixed_btn scroll-to-top show`}
          style={{
            bottom:
              cartNoti ||
              !location?.pathname === '/cart' ||
              !location?.pathname === '/checkout'
                ? '13rem'
                : '6rem',
          }}
        >
          <div id="chat-btn" className="w_button">
            <LazyLoad height={200}>
              <img
                src={whasapp}
                title="whatsapp"
                loading="eager"
                alt="whatsapp"
              />
            </LazyLoad>
          </div>
        </a>
      )}
    </div>
  );
}

export default WhatsAppIcon;

WhatsAppIcon.propTypes = {
  cartNoti: PropTypes.any,
};
