import React from 'react';
import PropTypes from 'prop-types';
import { CardSubtitle, CardTitle, Modal, ModalBody } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import CompleteOrder from 'components/ecommerce/productCards/CompleteOrder';
import usePrice from 'components/hooks/usePrice';

const PersonalizeModal = ({ onCloseClick, show, handleCart }) => {
  const { productDetail } = useSelector((state) => ({
    productDetail: state.Products.productDetail,
  }));

  const product = productDetail?.productDetail;

  const { stock } = usePrice(product);

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={personalizeModalStyle}
      className="delete-modal "
    >
      <ModalBody style={personalizeModalBodyStyle} className="">
        <FaTimes className="modal-close-icon-cart" onClick={onCloseClick} />{' '}
        <CardTitle>Add Personalization </CardTitle>
        <CardSubtitle className="mb-3">
          To purchase this item, kindly provide the necessary data so that we
          can create a customized product for you.
        </CardSubtitle>
        <CompleteOrder
          onCloseClick={onCloseClick}
          stock={stock}
          handleCart={handleCart}
        />
      </ModalBody>
    </Modal>
  );
};

PersonalizeModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  onDeleteClick: PropTypes.func,
  loading: PropTypes.bool,
  handleCart: PropTypes.any,
};

export default PersonalizeModal;

const personalizeModalStyle = {
  width: 300,
};
const personalizeModalBodyStyle = {
  minHeight: 300,
};
